<template>
  <div
    id="MainDialog"
    ref="ShareDialog"
    class="
      fixed
      bottom-full
      left-0
      right-0
      h-screen
      z-40
      flex
      items-center
      justify-center
    "
  >
    <div
      class="w-full max-w-md bg-main-blue-color p-5 relative z-10 rounded-md"
    >
      <h4 class="mb-5 pb-3 border-b border-white text-white font-bold">訊息</h4>
      <div class="py-2 text-sm text-white">
        <p>{{ msg }}</p>
      </div>
      <div class="text-right mt-3">
        <button
          class="
            px-5
            py-2
            text-main-blue-color
            rounded-md
            transition-all
            duration-300
            bg-white
            hover:text-black
          "
          @click="Close"
        >
          關閉
        </button>
      </div>
    </div>
    <div
      class="absolute bg-white z-0 top-0 left-0 right-0 bottom-0 opacity-70"
    ></div>
  </div>
</template>

<script>
import { OpenDialogGSAP, CloseDialogGSAP } from "@/gsap/share_dialog";
export default {
  name: "MainDialog",
  methods: {
    Close() {
      this.$store.commit("SetMainDialog", { action: false, msg: "" });
    },
  },
  computed: {
    status() {
      return this.$store.state.main_dialog.status;
    },
    msg() {
      return this.$store.state.main_dialog.msg;
    },
  },
  watch: {
    status() {
      if (this.status) {
        OpenDialogGSAP(this.$refs.ShareDialog);
      } else {
        CloseDialogGSAP(this.$refs.ShareDialog);
      }
    },
  },
  mounted() {
    if (this.status) {
      OpenDialogGSAP(this.$refs.ShareDialog);
    } else {
      CloseDialogGSAP(this.$refs.ShareDialog);
    }
  },
};
</script>