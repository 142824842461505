import store from "@/store/index";
import imagesloaded from "imagesloaded";
import { gsap } from "./gsap_loader";

export function LoadImage() {
  store.commit("SetImageLoaded", false);
  store.commit("SetLoading", 1);
  const images = gsap.utils.toArray("img");
  //完成事件
  const showDemo = () => {
    //設定讀取完成
    store.commit("SetLoading", -1);
    store.commit("SetImageLoaded", true);
    console.log("all images have been loaded!");
  };

  imagesloaded(images).on("always", showDemo);
}
