<template>
  <li :class="menu_bg ? 'text-black' : `text-${header_style}`">
    <router-link
      :to="url"
      class="flex items-center justify-center xl:px-10 px-8 py-7 font-bold transition-colors duration-300 border-b-2 border-transparent"
      :class="
        highline
          ? 'bg-main-green-color text-white hover:border-white'
          : 'hover:text-main-blue-color hover:border-main-blue-color'
      "
      >{{ title }}</router-link
    >
  </li>
</template>

<script>
export default {
  name: 'PCMenuItem',
  props: {
    header_style: String,
    menu_bg: Boolean,
    title: String,
    url: String,
    highline: Boolean,
  },
}
</script>
