<template>
  <div class="flex items-center">
    <ol class="flex items-center">
      <template v-for="item in menu_list">
        <PCMenuItem
          v-if="item.type != 'dropdown'"
          :key="item.title"
          :title="item.title"
          :url="item.url"
          :highline="item.highline"
          :header_style="header_style"
          :menu_bg="menu_bg"
        />
        <PCMenuDropdown
          v-if="item.type == 'dropdown'"
          :key="item.title"
          :title="item.title"
          :list="item.list"
          :highline="item.highline"
          :header_style="header_style"
          :menu_bg="menu_bg"
        />
      </template>
    </ol>
    <div
      class="flex items-center xl:ml-20 ml-10 font-bold"
      :class="menu_bg ? 'text-black' : `text-${header_style}`"
    >
      <router-link
        v-if="account_data == null"
        to="/login"
        class="transition-all duration-300 hover:text-main-blue-color"
        >登入</router-link
      >
      <template v-else>
        <router-link
          to="/member/contract"
          class="transition-all duration-300 text-white bg-main-blue-color hover:bg-sub-blue-color px-4 py-2 rounded-md text-sm"
          >會員中心</router-link
        >
        <span
          class="block h-5 mx-5 w-[1.5px] text-sm"
          :class="menu_bg ? 'bg-black' : `bg-${header_style}`"
        ></span>
        <button
          @click="Logout"
          class="transition-all duration-300 hover:text-main-blue-color text-sm"
        >
          登出
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import PCMenuItem from './PCMenuItem.vue';
import PCMenuDropdown from './PCMenuDropdown.vue';
import { delLocalStorage } from '@/common/cookie';
export default {
  name: 'PCMenu',
  props: {
    header_style: String,
    menu_bg: Boolean,
  },
  components: {
    PCMenuItem,
    PCMenuDropdown,
  },
  data() {
    return {
      about_drop_down: false,
      investment_drop_down: false,
      question_drop_down: false,
      menu_list: [
        {
          title: '認識電廠',
          type: 'dropdown',
          highline: false,
          list: [
            {
              title: '如何運作',
              url: '/operation_model',
            },
            {
              title: '認識電廠專家',
              url: '/about_market',
            },
            {
              title: '最新消息',
              url: '/news_list',
            },
            // {
            //   title: '媒體報導',
            //   url: '/reports',
            // },
            {
              title: '電廠小百科',
              url: '/wiki_list',
            },
            {
              title: '聯絡我們',
              url: '/contact_us',
            },
          ],
        },
        {
          title: '出租屋頂',
          type: 'list_item',
          highline: false,
          url: '/rent',
        },
        // {
        //   title: '常見問題',
        //   type: 'dropdown',
        //   highline: false,
        //   list: [
        //     {
        //       title: '電廠收益',
        //       url: '/question?tab=1',
        //     },
        //     {
        //       title: '關於認購',
        //       url: '/question?tab=2',
        //     },
        //     {
        //       title: '關於會員',
        //       url: '/question?tab=3',
        //     },
        //     {
        //       title: '文件說明',
        //       url: '/question?tab=4',
        //     },
        //   ],
        // },
        {
          title: '聯絡我們',
          type: 'list_item',
          highline: false,
          url: '/contact_us',
        },
        {
          title: '參與認購',
          type: 'list_item',
          highline: true,
          url: '/investment',
        },
      ],
    };
  },
  methods: {
    Logout() {
      delLocalStorage('account_data');
      delLocalStorage('account_token');
      this.$store.commit('SetAccountData', null);
      this.$router.push('/login');
    },
  },
  computed: {
    account_data() {
      return this.$store.state.account_data;
    },
  },
};
</script>
