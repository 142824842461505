<template>
  <section id="HomeNews" ref="HomeNews" class="relative overflow-hidden">
    <div
      class="w-full absolute top-0 left-0 right-0 bottom-0 bg-main-gray-color"
    >
      <img
        src="/img/home/home_5.news_img_1.jpg"
        class="
          block
          relative
          z-0
          lg:w-1/2
          w-full
          lg:h-full
          h-[300px]
          object-cover
          transform
          lg:-translate-x-[200px]
        "
      />
    </div>

    <div
      v-if="news_data != null"
      class="
        w-full
        flex
        lg:flex-row
        flex-col
        relative
        max-w-screen-xl
        mx-auto
        xl:px-32
        px-10
      "
    >
      <div
        class="
          w-1/3
          lg:h-auto
          h-[300px]
          lg:py-40 lg:block
          flex flex-col
          items-start
          justify-center
        "
      >
        <h2 class="text-white font-bold text-6xl mb-2 overflow-hidden">
          <span class="block title">NEWS</span>
        </h2>
        <h4 class="text-white font-bold text-2xl overflow-hidden">
          <span class="sub_title block">最新消息</span>
        </h4>
      </div>
      <div
        class="
          lg:w-2/3 lg:py-40 lg:px-10
          py-20
          w-full
          flex flex-col
          lg:items-end
          items-start
          content
        "
      >
        <ol class="w-full">
          <li
            class=""
            v-for="(item, item_index) in news_data.slice(0, 5)"
            :key="item.title"
          >
            <router-link
              :to="`/news/${item.news_id}`"
              @mouseenter.native="hover_news = item_index"
              @mouseleave.native="hover_news = -1"
              class="
                cursor-pointer
                flex
                md:flex-row
                flex-col
                md:items-center
                items-start
                md:py-5
                pt-5
                pb-2
                border-b-2 border-black
              "
            >
              <div class="flex items-center md:mb-0 mb-2">
                <p class="md:mr-10 mr-2 w-24 font-bold">
                  {{ item.created_at.slice(0, 10) }}
                </p>
                <span
                  class="
                    px-2
                    py-1
                    mr-10
                    md:text-sm
                    text-xs
                    border-2 border-sub-blue-color
                    text-sub-blue-color
                    rounded-full
                  "
                  >{{ item.category }}</span
                >
              </div>
              <p
                :class="hover_news == item_index ? 'text-main-blue-color' : ''"
                class="flex-1 w-full truncate transition-all duration-300"
              >
                {{ item.title }}
              </p>
            </router-link>
          </li>
        </ol>

        <MainLinkBtn
          class="mt-10"
          color="main-blue-color"
          hover_color="#005672"
          link="/news_list"
          text="VIEW ALL"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MainLinkBtn from "@/components/MainLinkBtn.vue";
import { NewsGSAP } from "@/gsap/home";
import { LoadImage } from "@/gsap/image_loaded";
export default {
  name: "HomeNews",
  components: {
    MainLinkBtn,
  },
  data() {
    return {
      hover_news: -1,
    };
  },
  computed: {
    news_data() {
      return this.$store.state.news_data;
    },
  },
  watch: {
    "$store.state.page_image_loaded"() {
      if (this.news_data != null && this.$store.state.page_image_loaded) {
        NewsGSAP(this.$refs.HomeNews);
      }
    },
    news_data() {
      if (this.news_data != null && this.$store.state.page_image_loaded) {
        NewsGSAP(this.$refs.HomeNews);
      } else if (
        this.news_data != null &&
        !this.$store.state.page_image_loaded
      ) {
        LoadImage();
      }
    },
  },
  mounted() {
    if (this.$store.state.page_image_loaded) {
      NewsGSAP(this.$refs.HomeNews);
    } else {
      LoadImage();
    }
  },
  created() {
    if (this.news_data == null) {
      this.$store.dispatch("getNewsData");
    }
  },
};
</script>