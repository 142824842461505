<template>
  <div
    ref="Menu"
    class="fixed -top-full left-0 right-0 h-screen overflow-y-auto bg-main-gray-color"
  >
    <button
      @click="$emit('close-action')"
      class="bg-main-blue-color flex items-center justify-center w-10 h-10 text-white rounded-full absolute top-5 right-5 transition-all duration-300 border-2 hover:border-main-blue-color hover:bg-transparent hover:text-main-blue-color"
    >
      <span class="material-symbols-outlined"> close </span>
    </button>
    <div class="md:px-10 px-5 w-full flex flex-col items-center md:pt-32 pt-20">
      <router-link to="/" @click.native="$emit('close-action')"
        ><img class="block md:w-60 w-44" src="/img/logo.svg"
      /></router-link>

      <div class="w-full flex flex-wrap md:mt-20 mt-10">
        <div class="px-5 order-2 md:w-1/3 w-1/2 md:mb-0 mb-10 flex">
          <div class="w-full">
            <p
              class="block font-black text-main-blue-color border-b-2 border-main-blue-color pb-2"
            >
              認識電廠
            </p>
            <ol class="mt-2">
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/operation_model"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >如何運作</router-link
                >
              </li>
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/about_market"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >認識電廠專家</router-link
                >
              </li>
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/news_list"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >最新消息</router-link
                >
              </li>
              <!-- <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/reports"
                  class="
                    font-bold
                    text-sm
                    py-2
                    block
                    transition-colors
                    duration-300
                    hover:text-main-blue-color
                  "
                  >媒體報導</router-link
                >
              </li> -->
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/wiki_list"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >電廠小百科</router-link
                >
              </li>
            </ol>
          </div>
        </div>
        <div class="px-5 order-3 md:w-1/3 w-1/2 md:mb-0 mb-10">
          <div class="flex flex-col h-full">
            <p
              class="block w-full font-black text-main-green-color border-b-2 border-main-green-color pb-2"
            >
              參與認購
            </p>
            <ol class="mt-2 flex-1 block">
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/investment"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >填寫表單</router-link
                >
              </li>
            </ol>
            <p
              class="font-black text-main-blue-color block border-b-2 border-main-blue-color pb-2"
            >
              出租屋頂
            </p>
            <ol class="mt-2 inline-block">
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/rent"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >填寫表單</router-link
                >
              </li>
            </ol>
          </div>
        </div>
        <!-- <div class="px-5 order-4 md:w-1/3 w-1/2 flex flex-col">
          <div class="flex flex-col">
            <p
              class="inline-block font-black text-main-blue-color border-b-2 border-main-blue-color pb-2"
            >
              常見問題
            </p>
            <ol class="mt-5 inline-block">
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/question?tab=1"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >電廠收益</router-link
                >
              </li>
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/question?tab=2"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >關於認購</router-link
                >
              </li>
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/question?tab=3"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >關於會員</router-link
                >
              </li>
              <li>
                <router-link
                  @click.native="$emit('close-action')"
                  to="/question?tab=4"
                  class="font-bold text-sm py-2 block transition-colors duration-300 hover:text-main-blue-color"
                  >文件說明</router-link
                >
              </li>
            </ol>
          </div>
        </div> -->

        <div
          class="px-5 w-full md:order-5 order-1 flex flex-col md:mt-32 md:mb-0 mb-10 text-center"
        >
          <MainLinkBtn
            class="mx-auto"
            color="main-blue-color"
            hover_color="#005672"
            link="/contact_us"
            text="聯絡我們"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OpenMenuGSAP, CloseMenuGSAP } from '@/gsap/main_menu';
import MainLinkBtn from '@/components/MainLinkBtn';
export default {
  name: 'MBMenu',
  components: {
    MainLinkBtn,
  },
  props: {
    menu_status: Boolean,
  },
  watch: {
    menu_status() {
      if (this.menu_status) {
        OpenMenuGSAP(this.$refs.Menu);
      } else {
        CloseMenuGSAP(this.$refs.Menu);
      }
    },
  },
};
</script>
