import {gsap,ScrollTrigger} from "@/gsap/gsap_loader"

export function CoverGSAP(el){
    let title = el.querySelector(".title")
    let sub_title = el.querySelector(".sub_title")
    let cover_tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            toggleActions: "restart play play reverse"
        }
    })
    cover_tl.fromTo(title,{
        y:"105%"
    },{
        y:"0%"
    },0)
    cover_tl.fromTo(sub_title,{
        y:"105%"
    },{
        y:"0%"
    },1)
}

export function AboutGsap(el){
    let main_card = el.querySelector(".main_card")
    let content = el.querySelector(".content")
    let sub_content = el.querySelector(".sub_content")
    let link_button = el.querySelector(".link_button")


    ScrollTrigger.matchMedia({
	
        // large
        "(min-width: 768px)": function() {
            let about_tl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: "top center",
                    toggleActions: "restart play play reverse"
                }
            })
            
    about_tl.fromTo(main_card,{
        x:"100px",
        y:"-50%",
        opacity:0
    },{
        x:"0px",
        y:"-50%",
        opacity:1
    },0)
    about_tl.fromTo(content,{
        opacity:0
    },{
        opacity:1,
        delay:.3
    },0)
    about_tl.fromTo(sub_content,{
        opacity:0
    },{
        opacity:1,
        delay:.4
    },0)
    about_tl.fromTo(link_button,{
        opacity:0,
    },{
        opacity:1,
        delay:.4
    },0)
        },
      
        // small
        "(max-width: 768px)": function() {    
            let about_tl = gsap.timeline({
            scrollTrigger: {
                trigger: main_card,
                start: "top center",
                toggleActions: "restart play play reverse"
            }
        })
        
    about_tl.fromTo(main_card,{
        y:"100px",
        x:"-50%",
        opacity:0
    },{
        y:"0px",
        x:"-50%",
        opacity:1
    },0)
    about_tl.fromTo(content,{
        opacity:0
    },{
        opacity:1,
        delay:.3
    },0)
    about_tl.fromTo(sub_content,{
        opacity:0
    },{
        opacity:1,
        delay:.4
    },0)
    about_tl.fromTo(link_button,{
        opacity:0,
    },{
        opacity:1,
        delay:.4
    },0)
        },
          
      }); 
}

export function OperationGSAP(el){
    let title = el.querySelector(".title")
    let sub_title = el.querySelector(".sub_title")
    let img_box = el.querySelector(".img_box")
    let cover_tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            toggleActions: "restart play play reverse"
        }
    })
    cover_tl.fromTo(title,{
        y:"105%"
    },{
        y:"0%"
    },0)
    cover_tl.fromTo(sub_title,{
        y:"105%"
    },{
        y:"0%",
        delay:.3
    },0)
    cover_tl.fromTo(img_box,{
        y:"35%",
        opacity:0,
    },{
        y:"0%",
        opacity:1
    },1)
}

export function FeaturesGSAP(el){
    let title = el.querySelector(".title")
    let sub_title = el.querySelector(".sub_title")
    let content = el.querySelector(".content")
    let cards = el.querySelectorAll(".card")
    let text_box = el.querySelectorAll(".text_box")
    let cover_tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            toggleActions: "restart play play reverse"
        }
    })
    cover_tl.fromTo(title,{
        y:"105%"
    },{
        y:"0%"
    },0)
    cover_tl.fromTo(sub_title,{
        y:"105%"
    },{
        y:"0%",
        delay:.3
    },0)
    cover_tl.fromTo(content,{
        opacity:0,
    },{
        opacity:1,
        delay:.5
    },0)

    
    cover_tl.fromTo(cards,{
        y:"35%",
        opacity:0,
    },{
        y:"0%",
        opacity:1,
        delay:.5
    },0)
    cover_tl.fromTo(text_box,{
        y:"100%",
        opacity:0,
    },{
        y:"0%",
        opacity:1
    },1)
}

export function NewsGSAP(el){
    let title = el.querySelector(".title")
    let sub_title = el.querySelector(".sub_title")
    let content = el.querySelector(".content")
    let news_tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            toggleActions: "restart play play reverse"
        }
    })
    news_tl.fromTo(title,{
        y:"105%"
    },{
        y:"0%"
    },0)
    news_tl.fromTo(sub_title,{
        y:"105%"
    },{
        y:"0%",
        delay:.3
    },0)
    news_tl.fromTo(content,{
        opacity:0,
        x:"50px"
    },{
        opacity:1,
        x:"0px",
        delay:.5
    },0)
}

