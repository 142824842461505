<template>
  <header
    id="MainHeader"
    class="fixed z-30 top-0 left-0 right-0 transition-all duration-300 select-none"
    :class="menu_bg ? 'bg-white' : ''"
  >
    <div
      class="w-full max-w-screen-xl xl:px-0 px-5 mx-auto flex items-center justify-between"
    >
      <router-link to="/" class="relative block">
        <img class="md:w-32 w-24 relative z-0" src="/img/logo.svg" />
        <img
          class="md:w-32 w-24 absolute z-10 top-0 left-0"
          :class="
            menu_bg ? 'hidden' : header_style == 'white' ? 'block' : 'hidden'
          "
          src="/img/logo_w.svg"
        />
      </router-link>
      <div class="flex items-center md:py-5 py-3 lg:hidden">
        <router-link
          to="/login"
          class="cursor-pointer transition-all duration-300 hover:text-sub-blue-color"
          :class="menu_bg ? 'text-black' : `text-${header_style}`"
        >
          <span class="material-symbols-outlined md:text-4xl text-2xl mr-3">
            person
          </span>
        </router-link>
        <button
          @click="ToggleMenu(0)"
          class="cursor-pointer transition-all duration-300 hover:text-sub-blue-color"
          :class="menu_bg ? 'text-black' : `text-${header_style}`"
        >
          <span class="material-symbols-outlined md:text-4xl text-2xl">
            menu
          </span>
        </button>
      </div>

      <PCMenu
        class="lg:flex hidden"
        :header_style="header_style"
        :menu_bg="menu_bg"
        :menu_list="menu_list"
      />
      <MBMenu
        class="lg:hidden block"
        :menu_status="menu_status"
        @close-action="ToggleMenu(-1)"
      />
    </div>
  </header>
</template>

<script>
import PCMenu from '@/components/MainHeader/PCMenu.vue';
import MBMenu from '@/components/MainHeader/MBMenu.vue';
export default {
  name: 'MainHeader',
  components: {
    PCMenu,
    MBMenu,
  },
  data() {
    return {
      menu_list: [
        {
          title: '認識電廠',
          link: '/',
        },
        {
          title: '出租屋頂',
          link: '/',
        },
        {
          title: '常見問題',
          link: '/',
        },
      ],
      menu_status: false,
      menu_bg: false,
      scroll_timner: null,
    };
  },
  methods: {
    ToggleMenu(val) {
      if (val == -1) {
        this.menu_status = false;
      } else {
        this.menu_status = true;
      }
    },
    CheckScroll() {
      if (window.scrollY > 300) {
        this.menu_bg = true;
      } else {
        this.menu_bg = false;
      }
    },
  },
  computed: {
    header_style() {
      return this.$route.meta.header_style;
    },
  },
  mounted() {
    this.CheckScroll();
    window.addEventListener('scroll', () => {
      clearTimeout(this.scroll_timner);
      this.scroll_timner = setTimeout(() => {
        this.CheckScroll();
      }, 50);
    });
  },
};
</script>
