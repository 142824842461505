<template>
  <footer
    id="MainFooter"
    class="relative w-full py-40 border-t-8 border-sub-blue-color bg-main-gray-color"
  >
    <div class="block w-full mb-20 text-center lg:hidden">
      <PageTopBtn
        color="main-blue-color"
        hover_color="#005672"
        link="/"
        text="PAGE TOP"
        icon="expand_less"
      />
    </div>
    <div
      class="relative flex flex-col items-center w-full max-w-screen-xl px-10 mx-auto xl:px-32 md:flex-row md:items-start"
    >
      <div class="w-full text-center md:w-1/3 md:text-left">
        <img
          class="block max-w-full mx-auto w-44 md:mx-0"
          src="/img/logo.svg"
        />
        <div class="inline-block mx-auto mt-10 md:mt-10 md:mx-0">
          <p class="flex items-center mb-3 text-sm font-bold">
            <span class="block mr-2 font-black">公司地址</span>
            台北市信義區忠孝東路五段412號12樓之6
          </p>
          <p class="flex items-center mb-3 text-sm font-bold">
            <span class="block mr-2 font-black">連絡電話</span>
            02-25118781
          </p>
          <p class="flex items-center mb-3 text-sm font-bold">
            <span class="block mr-2 font-black">E-Mail</span>
            service@yh-solar.com.tw
          </p>
          <a
            href="https://www.facebook.com/yhc.solar"
            target="_blank"
            class="block px-5 py-2 mt-6 font-bold transition-colors duration-300 border-2 border-main-blue-color text-main-blue-color rounded-xl hover:bg-main-blue-color hover:text-white"
            >Facebook</a
          >
        </div>
      </div>
      <div class="flex w-full mt-20 md:w-2/3 md:mt-0">
        <div class="flex items-stretch justify-center w-full lg:w-2/3">
          <div class="px-5 md:w-1/3 md:px-0">
            <p class="font-black text-main-blue-color">認識電廠</p>
            <ol class="mt-5">
              <li>
                <router-link
                  to="/operation_model"
                  class="block py-2 text-sm font-bold"
                  >如何運作</router-link
                >
              </li>
              <li>
                <router-link
                  to="/about_market"
                  class="block py-2 text-sm font-bold"
                  >認識電廠專家</router-link
                >
              </li>
              <li>
                <router-link
                  to="/news_list"
                  class="block py-2 text-sm font-bold"
                  >最新消息</router-link
                >
              </li>
              <!-- <li>
                <router-link to="/reports" class="block py-2 text-sm font-bold"
                  >媒體報導</router-link
                >
              </li> -->
              <li>
                <router-link
                  to="/wiki_list"
                  class="block py-2 text-sm font-bold"
                  >電廠小百科</router-link
                >
              </li>
            </ol>
          </div>
          <div class="px-5 md:w-1/3 md:px-0">
            <p class="font-black text-main-blue-color">聯絡我們</p>
            <ol class="mt-5">
              <li>
                <router-link to="/rent" class="block py-2 text-sm font-bold"
                  >出租屋頂</router-link
                >
              </li>
              <li>
                <router-link
                  to="/investment"
                  class="block py-2 text-sm font-bold"
                  >參與認購</router-link
                >
              </li>
              <li>
                <router-link
                  to="/contact_us"
                  class="block py-2 text-sm font-bold"
                  >聯絡我們</router-link
                >
              </li>
            </ol>
          </div>
          <div class="px-5 md:w-1/3 md:px-0">
            <p class="font-black text-main-blue-color">常見問題</p>
            <ol class="mt-5">
              <li>
                <router-link
                  to="/question?tab=1"
                  class="block py-2 text-sm font-bold"
                  >電廠收益</router-link
                >
              </li>
              <li>
                <router-link
                  to="/question?tab=2"
                  class="block py-2 text-sm font-bold"
                  >關於認購</router-link
                >
              </li>
              <li>
                <router-link
                  to="/question?tab=3"
                  class="block py-2 text-sm font-bold"
                  >關於會員</router-link
                >
              </li>
              <li>
                <router-link
                  to="/question?tab=4"
                  class="block py-2 text-sm font-bold"
                  >文件說明</router-link
                >
              </li>
            </ol>
          </div>
        </div>
        <div class="hidden w-1/3 lg:block">
          <PageTopBtn
            color="main-blue-color"
            hover_color="#005672"
            link="/"
            text="PAGE TOP"
            icon="expand_less"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import PageTopBtn from "@/components/PageTopBtn";
export default {
  name: "MainFooter",
  components: {
    PageTopBtn,
  },
};
</script>
