<template>
  <div
    id="MainLoading"
    ref="Loading"
    class="fixed left-0 right-0 bottom-full h-screen z-40 flex items-center justify-center transform"
  >
    <div ref="content" class="relative z-10">
      <h1 class="title font-black sm:text-6xl text-5xl text-main-blue-color">
        LOADING
      </h1>
      <p class="flex items-center justify-center">
        <span
          class="dot block text-6xl text-sub-blue-color"
          v-for="i in 3"
          :key="`dot_${i}`"
          >·</span
        >
      </p>
    </div>
    <div
      class="absolute z-0 top-0 left-0 right-0 bottom-0 bg-white opacity-90"
    ></div>
  </div>
</template>

<script>
import { gsap } from '@/gsap/gsap_loader';
export default {
  name: 'MainLoading',
  data() {
    return {
      loading_tl: null,
      loading_show: null,
    };
  },
  methods: {
    LoadingGsap() {
      this.OpenLoading();
      this.loading_tl != null ? this.loading_tl.kill() : '';
      this.loading_tl = gsap.timeline({
        repeat: -1,
      });

      let content = this.$refs.content;
      let dot = content.querySelectorAll('.dot');
      let title = content.querySelector('.title');

      this.loading_tl
        .from(content, {
          duration: 1,
          opacity: 0.8,
          ease: 'back',
          repeat: -1,
          yoyo: true,
        })
        .from(dot, {
          duration: 0.5,
          yoyo: true,
          repeat: -1,
          y: '20',
          ease: 'back',
          stagger: {
            amount: 0.25,
            from: 'end',
          },
        })
        .from(
          title,
          {
            duration: 0.68,
            repeat: -1,
            yoyo: true,
            y: 15,
            ease: 'back',
          },
          '<'
        );
    },
    OpenLoading() {
      this.loading_show != null ? this.loading_show.kill() : '';
      this.loading_show = gsap.timeline();
      let el = this.$refs.Loading;

      this.loading_show.set(
        el,
        {
          y: '100%',
        },
        0
      );
      this.loading_show.to(
        el,
        {
          opacity: 1,
          delay: 0.4,
          duration: 0.5,
        },
        0
      );
    },
    CloseLoading() {
      this.loading_show != null ? this.loading_show.kill() : '';
      this.loading_show = gsap.timeline();
      let el = this.$refs.Loading;
      this.loading_show.to(
        el,
        {
          opacity: 0,
          delay: 0.5,
          duration: 1,
        },
        0
      );
      this.loading_show.set(
        el,
        {
          y: '0%',
          delay: 1.5,
        },
        0
      );
    },
  },
  computed: {
    loading() {
      return this.$store.state.main_loading;
    },
    image_loading() {
      return this.$store.state.page_image_loaded;
    },
  },
  watch: {
    loading() {
      if (this.loading > 0 && this.loading_tl == null) {
        this.LoadingGsap();
      } else if (this.loading <= 0) {
        this.CloseLoading();
        this.loading_tl != null ? (this.loading_tl = null) : '';
      }
    },
    image_loading() {
      if (!this.image_loading) {
        this.$store.commit('SetLoading', 1);
      } else {
        this.$store.commit('SetLoading', -1);
      }
    },
  },
  mounted() {
    if (!this.image_loading) {
      this.$store.commit('SetLoading', 1);
    }
    if (this.loading > 0) {
      this.LoadingGsap();
    } else {
      this.CloseLoading();
      this.loading_tl != null ? (this.loading_tl = null) : '';
    }
  },
};
</script>
