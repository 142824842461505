<template>
  <li
    :class="menu_bg ? 'text-black' : `text-${header_style}`"
    class="relative"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <button
      class="flex items-center justify-center xl:px-10 px-8 py-7 font-bold relative z-0 transition-all duration-300 hover:text-main-blue-color border-b-2 border-transparent hover:border-main-blue-color"
    >
      {{ title }}
    </button>
    <ol
      :class="
        hover
          ? 'pointer-events-auto z-30 opacity-100'
          : 'pointer-events-none z-[-1] opacity-0'
      "
      class="absolute z-10 overflow-hidden top-full left-0 right-0 bg-main-blue-color text-white text-sm transition-all duration-300 transform"
    >
      <li v-for="item in list" :key="item.title">
        <router-link
          v-if="item.out_link != true"
          :to="item.url"
          class="px-4 py-4 block transition-opacity duration-300 opacity-60 hover:opacity-100"
          >{{ item.title }}</router-link
        >
        <a
          v-else
          :href="item.url"
          target="_blank"
          class="px-4 py-4 block transition-opacity duration-300 opacity-60 hover:opacity-100"
          >{{ item.title }}</a
        >
      </li>
    </ol>
  </li>
</template>

<script>
export default {
  name: 'PCMenuDropdown',
  props: {
    header_style: String,
    menu_bg: Boolean,
    title: String,
    list: Array,
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
