import { gsap } from "@/gsap/gsap_loader"

export function OpenDialogGSAP(el) {
    let cover_tl = gsap.timeline()
    cover_tl.set(el, {
        y: "100%"
    }, 0)
    cover_tl.fromTo(el, {
        opacity: 0
    }, {
        opacity: 1,
        duration: .3
    }, 0)
}
export function CloseDialogGSAP(el) {
    let cover_tl = gsap.timeline()
    cover_tl.fromTo(el, {
        opacity: 1
    }, {
        opacity: 0,
        duration: .3
    }, 0)
    cover_tl.set(el, {
        y: "0%"
    }, 1)
}
