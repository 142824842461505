<template>
  <section
    id="HomeCover"
    ref="HomeCover"
    class="relative sm:w-full w-full sm:h-auto h-screen"
  >
    <div
      class="
        absolute
        w-full
        lg:pb-32 lg:pl-32
        sm:pb-10 sm:pl-10
        px-8
        sm:pt-0
        pt-40
        sm:bottom-0
        left-0
        sm:top-auto
        top-0
        z-10
      "
    >
      <h2
        class="
          lg:text-7xl
          sm:text-5xl
          text-6xl
          font-bold
          text-main-blue-color
          mb-5
          overflow-hidden
        "
      >
        <span class="block title">SOLAR ENERGY</span>
      </h2>
      <h4
        class="
          text-sub-blue-color
          lg:text-4xl
          sm:text-2xl
          text-2xl
          font-bold
          overflow-hidden
        "
      >
        <span class="block sub_title">產業專家邀您共創綠電收益</span>
      </h4>
    </div>
    <img
      src="/img/home/home_1.banner_img_1.jpg"
      class="
        w-full
        sm:h-auto
        h-full
        object-center object-cover
        block
        relative
        z-0
      "
    />
  </section>
</template>

<script>
import { CoverGSAP } from "@/gsap/home.js";
export default {
  name: "HomeCover",
  watch: {
    "$store.state.page_image_loaded"() {
      if (this.$store.state.page_image_loaded) {
        CoverGSAP(this.$refs.HomeCover);
      }
    },
  },
  mounted() {
    if (this.$store.state.page_image_loaded) {
      CoverGSAP(this.$refs.HomeCover);
    }
  },
};
</script>