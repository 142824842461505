<template>
  <section
    id="HomeFeatures"
    ref="HomeFeatures"
    class="relative bg-main-blue-color"
  >
    <div class="w-full relative max-w-screen-xl mx-auto xl:px-32 px-10 py-40">
      <div class="w-full text-white">
        <h2 class="sm:text-6xl text-4xl font-bold mb-2 overflow-hidden">
          <span class="block title">TRUST</span>
        </h2>
        <h4 class="text-white font-bold sm:text-2xl text-xl overflow-hidden">
          <span class="block sub_title">可信賴的太陽能電廠專家</span>
        </h4>
        <div
          class="
            w-full
            flex
            md:flex-row
            flex-col
            md:items-center md:justify-between
            mt-10
            content
          "
        >
          <p class="text-white font-bold">
            元和代管有保障，每一座電廠都獲得最佳的管理。
          </p>
          <MainLinkBtn
            color="white"
            hover_color="#005672"
            link="/about_market"
            text="VIEW ALL"
          />
        </div>

        <div
          class="
            relative
            flex
            items-stretch
            justify-center
            flex-wrap
            lg:-mx-10
            -mx-5
            mt-20
          "
        >
          <div
            class="
              md:w-1/2
              w-full
              lg:px-10
              px-5
              lg:mb-20
              mb-10
              relative
              overflow-hidden
              card
            "
            v-for="(item, item_index) in features"
            :key="item.title"
          >
            <div
              class="
                text_box
                absolute
                z-10
                w-2/3
                bottom-0
                lg:right-10
                right-5
                bg-main-blue-color
                text-white
                rounded-tl-3xl
                flex
                items-center
                pt-5
                px-5
              "
            >
              <span class="md:text-6xl text-4xl font-medium mr-5"
                >0{{ item_index + 1 }}</span
              >
              <p class="lg:text-xl text-base font-bold">{{ item.title }}</p>
            </div>
            <img :src="item.image" class="block relative z-0" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainLinkBtn from "../MainLinkBtn.vue";
import { FeaturesGSAP } from "@/gsap/home";

export default {
  name: "HomeFeatures",
  components: {
    MainLinkBtn,
  },
  data() {
    return {
      features: [
        {
          title: "電廠皆先建後售降低認購風險",
          image: "/img/home/home_4.trust_img_01.jpg",
        },
        {
          title: "高規格標準驗收電廠",
          image: "/img/home/home_4.trust_img_02.jpg",
        },
        {
          title: "設備投保產險有保障",
          image: "/img/home/home_4.trust_img_03.jpg",
        },
        {
          title: "科技監控確保發電效能",
          image: "/img/home/home_4.trust_img_04.jpg",
        },
      ],
    };
  },
  watch: {
    "$store.state.page_image_loaded"() {
      if (this.$store.state.page_image_loaded) {
        FeaturesGSAP(this.$refs.HomeFeatures);
      }
    },
  },
  mounted() {
    if (this.$store.state.page_image_loaded) {
      FeaturesGSAP(this.$refs.HomeFeatures);
    }
  },
};
</script>