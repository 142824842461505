<template>
  <main id="HomePage" class="w-full relative z-10">
    <Cover />
    <Subscribe />
    <OperationModel />
    <Features />
    <News />
  </main>
</template>

<script>
// @ is an alias to /src
import Cover from '@/components/Home/Cover.vue';
import Subscribe from '@/components/Home/Subscribe.vue';
import OperationModel from '@/components/Home/OperationModel.vue';
import Features from '@/components/Home/Features.vue';
import News from '@/components/Home/News.vue';
import { GetMetaData } from '@/common/meta';

export default {
  name: 'HomeView',
  components: {
    Cover,
    Subscribe,
    OperationModel,
    Features,
    News,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  mounted() {
    this.meta_data = GetMetaData('首頁', '', '');
    window.prerenderReady = true;
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
