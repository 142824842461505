import Vue from 'vue';
import Vuex from 'vuex';
import { getNewsData, getReportsData, getWikiData } from '@/api/page';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_menu: false,
    page_image_loaded: false,
    main_loading: 0,
    main_dialog: {
      msg: '',
      status: false,
    },
    news_data: null,
    reports_data: null,
    wiki_data: null,
    account_data: null,
  },
  mutations: {
    SetMainMenu(state, action) {
      state.main_menu = action;
      state.blur = action;
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action;
    },
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetMainDialog(state, { msg, action }) {
      state.main_dialog.msg = msg;
      state.main_dialog.status = action;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
    SetAccountData(state, val) {
      state.account_data = val;
    },
  },
  actions: {
    getNewsData(state) {
      getNewsData().then((res) => {
        state.commit('SetStateData', {
          key: 'news_data',
          val: res.data,
        });
      });
    },
    getReportsData(state) {
      getReportsData().then((res) => {
        state.commit('SetStateData', {
          key: 'reports_data',
          val: res.data,
        });
      });
    },
    getWikiData(state) {
      getWikiData().then((res) => {
        state.commit('SetStateData', {
          key: 'wiki_data',
          val: res.data,
        });
      });
    },
  },
  modules: {},
});
