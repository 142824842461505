import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store/index";
import { gsap } from "@/gsap/gsap_loader";
import { getLocalStorage } from "@/common/cookie";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      header_style: "black",
    },
    component: HomeView,
  },
  {
    path: "/operation_model",
    name: "operation_model",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(
        /* webpackChunkName: "operation_model" */ "../views/OperationModel.vue"
      ),
  },
  {
    path: "/about_market",
    name: "about_market",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "about_market" */ "../views/AboutMarket.vue"),
  },
  {
    path: "/news_list",
    name: "news_list",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "news_list" */ "../views/NewsList.vue"),
  },
  {
    path: "/news/:id",
    name: "news_page",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "news_page" */ "../views/NewsPage.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/ReportList.vue"),
  },
  {
    path: "/report/:id",
    name: "report_page",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "report_page" */ "../views/ReportPage.vue"),
  },
  {
    path: "/wiki_list",
    name: "wiki_list",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "wiki_list" */ "../views/KnowledgeList.vue"),
  },
  {
    path: "/wiki/:id",
    name: "wiki_page",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "wiki_page" */ "../views/KnowledgePage.vue"),
  },
  {
    path: "/question",
    name: "question",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "question" */ "../views/Question.vue"),
  },
  {
    path: "/contact_us",
    name: "contact_us",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "contact_us" */ "../views/ContactUs.vue"),
  },
  {
    path: "/investment",
    name: "investment",
    meta: {
      header_style: "white",
    },
    component: () =>
      import(/* webpackChunkName: "investment" */ "../views/Investment.vue"),
  },
  {
    path: "/rent",
    name: "rent",
    meta: {
      header_style: "white",
    },
    component: () => import(/* webpackChunkName: "rent" */ "../views/Rent.vue"),
  },
  {
    path: "/send_finish",
    name: "send_finish",
    meta: {
      header_style: "black",
    },
    component: () =>
      import(/* webpackChunkName: "send_finish" */ "../views/SendFinish.vue"),
  },
  {
    path: "/error_page",
    name: "error_page",
    meta: {
      header_style: "black",
    },
    component: () =>
      import(/* webpackChunkName: "error_page" */ "../views/404Page.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      header_style: "black",
    },
    beforeEnter: (to, from, next) => {
      getLocalStorage("account_token") != null
        ? next("/member/contract")
        : next();
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/member",
    name: "member_center",
    component: () =>
      import(
        /* webpackChunkName: "member_center" */ "../views/MemberCenter.vue"
      ),
    beforeEnter: (to, from, next) => {
      getLocalStorage("account_token") == null ? next("/login") : next();
    },
    children: [
      {
        path: "contract",
        name: "contract",
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "../views/Member/Contract.vue"
          ),
      },
    ],
  },
  {
    path: "*",
    redirect: "/error_page",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name != from.name) {
    store.commit("SetImageLoaded", false);
    Vue.nextTick(() => {
      let tl = gsap.timeline();
      let el = document.querySelector("#MainLoading");
      tl.set(
        el,
        {
          y: "100%",
        },
        0
      );
      tl.to(
        el,
        {
          opacity: 1,
          duration: 0.5,
        },
        0
      );
      next();
    });
  } else {
    next();
  }
});

export default router;
