var default_meta = {
  title: '首頁',
  content:
    '是一個提供全民以小額資金參與認購太陽能發電廠的平台 您不需提供場地也不須管理電廠，只要用小額資金透過全民電廠認購太陽能板後委託元和進行管理，即可享有台電20年的躉售收益',
  image: 'https://www.yh-solar.com.tw/img/share/index.jpg',
};

export function GetMetaData(title, content, image) {
  title = title == '' ? default_meta.title : title;
  return {
    title: '元和科技 - ' + title,
    meta: [
      {
        property: 'og:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        content: content == '' ? default_meta.content : content,
        vmid: 'description',
      },
      {
        property: 'og:image',
        content: image == '' ? default_meta.image : image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        content: '元和科技 - ' + title,
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        content: '元和科技 - ' + title,
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        content: '元和科技 - 官方網站',
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  };
}
