<template>
  <section id="OperationModel" ref="OperationModel" class="relative w-full">
    <div
      class="w-full relative max-w-screen-xl mx-auto xl:px-32 px-10 pt-40 pb-32"
    >
      <div class="w-full text-center">
        <h2
          class="
            text-main-blue-color
            font-bold
            sm:text-6xl
            text-5xl
            mb-5
            overflow-hidden
          "
        >
          <span class="block title">OPERATION MODEL</span>
        </h2>
        <h4
          class="
            sm:text-2xl
            text-xl text-sub-blue-color
            font-black
            overflow-hidden
          "
        >
          <span class="block sub_title">全民電廠運作模式</span>
        </h4>
      </div>

      <div class="w-full sm:mt-32 mt-20 img_box">
        <img
          src="/img/home/home_3.operation_img.jpg"
          class="w-full sm:block hidden"
        />
        <img src="/img/home/home_img_sm_1.jpg" class="w-full sm:hidden block" />
      </div>
    </div>
  </section>
</template>

<script>
import { OperationGSAP } from "@/gsap/home";
export default {
  name: "HomeOperationModel",
  watch: {
    "$store.state.page_image_loaded"() {
      if (this.$store.state.page_image_loaded) {
        OperationGSAP(this.$refs.OperationModel);
      }
    },
  },
  mounted() {
    if (this.$store.state.page_image_loaded) {
      OperationGSAP(this.$refs.OperationModel);
    }
  },
};
</script>