<template>
  <a
    @mouseenter="HoverAction"
    @mouseleave="LeaveAction"
    ref="button"
    :href="link"
    class="
      bg-transparent
      w-44
      pt-3
      pb-4
      inline-flex
      items-center
      justify-between
      relative
    "
  >
    <span class="text font-black" :class="`text-${color}`">{{ text }}</span>

    <div
      class="icon rounded-full w-7 h-7 flex items-center justify-center"
      :class="`bg-${color}`"
    >
      <span
        class="material-symbols-outlined block text-lg"
        :class="
          color == 'main-blue-color' ? `text-white` : `text-main-blue-color`
        "
      >
        {{ icon }}
      </span>
    </div>
    <i
      class="absolute bottom-0 left-0 right-0 h-[2px] line"
      :class="`bg-${color}`"
    ></i>
  </a>
</template>

<script>
import gsap from "gsap";
export default {
  name: "MainLinkBtn",
  props: {
    color: {
      type: String,
      default: "main-blue-color",
    },
    hover_color: {
      type: String,
      default: "#005672",
    },
    icon: {
      type: String,
      default: "chevron_right",
    },
    link: {
      type: String,
      default: "/",
    },
    text: {
      type: String,
      default: "VIEW ALL",
    },
  },
  data() {
    return {
      hover_tl: null,
    };
  },
  methods: {
    HoverAction() {
      this.hover_tl = gsap.timeline();
      let button = this.$refs.button;
      let text = button.querySelector(".text");
      let icon = button.querySelector(".icon");
      let icon_text = button.querySelector(".icon span");
      let line = button.querySelector(".line");

      this.hover_tl.to(
        text,
        {
          color: this.hover_color,
          x: "5px",
        },
        0
      );
      this.hover_tl.to(
        icon,
        {
          backgroundColor: "transparent",
        },
        0
      );
      this.hover_tl.to(
        icon_text,
        {
          color: this.hover_color,
        },
        0
      );
      this.hover_tl.fromTo(
        line,
        {
          scaleX: 1,
          backgroundColor: this.color,
          transformOrigin: "right",
        },
        {
          scaleX: 0,
          backgroundColor: this.color,
          transformOrigin: "right",
          duration: 0.2,
        },
        0
      );
      this.hover_tl.fromTo(
        line,
        {
          backgroundColor: this.hover_color,
          scaleX: 0,
          transformOrigin: "left",
        },
        {
          backgroundColor: this.hover_color,
          scaleX: 1,
          transformOrigin: "left",
          duration: 0.2,
          delay: 0.2,
        },
        0
      );
    },
    LeaveAction() {
      this.hover_tl.reverse();
    },
  },
};
</script>