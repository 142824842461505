<template>
  <section id="HomeSubscribe" ref="HomeSubscribe" class="relative w-full">
    <div class="w-full relative max-w-screen-xl mx-auto xl:px-32 md:pb-0 pb-32">
      <div class="md:w-[640px] w-full relative z-0">
        <img src="/img/home/home_2.about_img_1.jpg" class="w-full block" />
      </div>
      <div
        class="
          main_card
          absolute
          z-10
          md:top-1/2 md:right-0 md:left-auto md:bottom-auto
          left-1/2
          bottom-0
          md:w-1/2
          w-11/12
          transform
          md:rounded-none
          rounded-xl
          bg-white
          p-16
        "
      >
        <p
          class="
            content
            sm:text-xl
            text-base
            font-bold
            text-sub-blue-color
            leading-normal
            mb-5
          "
        >
          全民電廠邀您用小額資金認購太陽能板，
          結合眾人的力量實現綠能減碳並創造發電 收益，共同打造綠電家園
        </p>
        <p
          class="
            sub_content
            text-main-blue-color
            font-bold
            sm:text-base
            text-sm
          "
        >
          We invite you to subscribe to solar panels with small amounts of
          money, and to jointly contribute to environmental protection, making
          Taiwan a green-energy homeland.
        </p>
        <router-link
          @mouseenter.native="button_hover = true"
          @mouseleave.native="button_hover = false"
          to="/investment"
          class="
            link_button
            rounded-lg
            md:w-56
            w-full
            mt-10
            py-3
            px-5
            inline-flex
            items-center
            justify-between
            transition-colors
            duration-300
            border-2
          "
          :class="
            button_hover
              ? 'bg-transparent border-main-green-color'
              : 'bg-main-green-color border-transparent'
          "
        >
          <span
            :class="button_hover ? 'text-main-green-color' : 'text-white'"
            class="transition-all duration-300 font-bold"
            >參與認購</span
          >

          <div
            class="
              bg-white
              rounded-full
              w-7
              h-7
              flex
              items-center
              justify-center
            "
          >
            <span
              class="
                material-symbols-outlined
                block
                text-lg text-main-green-color
              "
            >
              chevron_right
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { AboutGsap } from "@/gsap/home";
export default {
  name: "HomeSubscribe",
  data() {
    return {
      button_hover: false,
    };
  },
  watch: {
    "$store.state.page_image_loaded"() {
      if (this.$store.state.page_image_loaded) {
        AboutGsap(this.$refs.HomeSubscribe);
      }
    },
  },
  mounted() {
    if (this.$store.state.page_image_loaded) {
      AboutGsap(this.$refs.HomeSubscribe);
    }
  },
};
</script>