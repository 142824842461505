<template>
  <div id="app" class="w-full min-h-screen flex flex-col overflow-x-hidden">
    <MainLoading />
    <MainDialog />
    <MainHeader />
    <router-view class="flex-1"></router-view>
    <MainFooter />
  </div>
</template>

<script>
import MainFooter from './components/MainFooter.vue';
import MainHeader from '@/components/MainHeader.vue';
import MainLoading from '@/components/MainLoading.vue';
import MainDialog from './components/MainDialog.vue';
import { LoadImage } from '@/gsap/image_loaded';
import { getLocalStorage } from '@/common/cookie';
export default {
  name: 'App',
  components: {
    MainFooter,
    MainHeader,
    MainLoading,
    MainDialog,
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) {
        this.$store.commit('SetImageLoaded', false);
      }
    },
    main_loading() {
      if (this.main_loading != 0) {
        document.body.style.overflowY = 'hidden';
      } else if (this.page_image_loaded) {
        document.body.style.overflowY = 'auto';
        window.scrollTo(0, 0);
      }
    },
    page_image_loaded() {
      if (!this.page_image_loaded) {
        document.body.style.overflowY = 'hidden';
      } else if (this.main_loading == 0) {
        document.body.style.overflowY = 'auto';
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    main_loading() {
      return this.$store.state.main_loading;
    },
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
  mounted() {
    LoadImage();
  },
  created() {
    let account_data = getLocalStorage('account_data');
    account_data != null
      ? this.$store.commit('SetAccountData', JSON.parse(account_data))
      : '';
  },
};
</script>

<style src="@/assets/css/output.css"></style>
